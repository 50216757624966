import React from "react";
import { Button, Card } from "react-bootstrap";
import Header from "../../components/common/Header";
import { useTranslation } from "react-i18next";

import { ethers } from "ethers";
import { isInWeb3Browser, connect, switchNetwork, ABI_ERC721, ABI_APP1, onAccountChange } from "../../common/dapp.js";
import { CHAIN_EXPLORER, CHAIN_ID, CHAIN_NAME, CHAIN_SYMBOL, EGGX_APP1_CONTRACT, NFT_CONTRACT, RPC } from "../../common/constant.js";
import { fetchText, postJson } from "../../common/rest.js";

import copy from 'copy-to-clipboard';
// i18n
import '../../i18n.js'

const API = 'https://meta-api-v2.metadragon.ai';

const ABI = [
    'function parent(address addr) view returns (address)',
    'function kids(address _addr) external view returns(address[])',
    'function addressToInviteCode(address addr) view returns (bytes)',
    'function bindReferrer(bytes memory referrerCode) external'
]

class _App12Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: null,
            totalSupply: 0,
            totalReleased: 0,
            rewardPerOption: 0,
            rewardPerOption1: 0,


            loading: false,
            currentTokens: [],
            txHash: null,
            showSuccessInfo: false,

            chainId: null,
            showRule: false,

            parent: null,
            
            loadingMessage: 'Loading...',

            loadingEnabled: true,
            canCollect : false

        }
    }

    async componentDidMount() {
        this.onLoad();
    }

    _loadParent = async (options) => {
        const contract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI, options.provider);
        const parent = await contract.parent(options.address);

        this.setState({
            parent
        });
    }

    scanAndLoadTokens = async () => {
        const t = this.props.t;
        // if parent is null, then alert
        console.log(this.state.parent);
        if (!this.state.parent || '0x0000000000000000000000000000000000000000' === this.state.parent) {
            this.alert(t('App1Alert1'));
            return;
        }

        // check loading
        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        });

        try {
            // 遍历所有的NFT， 寻找适合的NFT，如果没有，提示无法再继续合成了
            const options = await connect();
            const provider = options.provider;
            const address = options.address;

            // nft contract
            const nftContract = new ethers.Contract(NFT_CONTRACT, ABI_ERC721, provider);
            const appContract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI_APP1, provider);
            // balanceOf 
            const balance = await nftContract.balanceOf(address);
            // all tokens
            const metas = [];

            for (let i = 0; i < balance; i++) {
                const tokenId = await nftContract.tokenOfOwnerByIndex(address, i);
                console.log(t('App11Loading', { id: tokenId }))
                this.setState({
                    loadingMessage: t('App11Loading', { id: tokenId })
                });

                // tokensIds.push(token);
                const isUsed = await appContract.isTokenIdUsed(tokenId);
                if (isUsed) {
                    console.log('token is used already: ' + tokenId);
                    continue;
                }

                // const tokenUri = await nftContract.tokenURI(tokenId);
                const tokenUri = 'https://meta.metadragon.ai/?id=' + tokenId;
                const resp = await fetchText(tokenUri);

                const meta = JSON.parse(resp);
                // 从attributes中获取属性，并用map的方式attach到meta上
                const attributes = meta.attributes;
                const attrMap = {};
                for (let j = 0; j < attributes.length; j++) {
                    const attr = attributes[j];
                    attrMap[attr.trait_type] = attr.value;
                }
                meta.attrs = attrMap;
                meta.tokenId = tokenId;

                metas.push(meta);
            }



            if (metas.length < 5) {
                this.alert(t('App11Error1'))
                return;
            }

            const nfts = this._selectNfts(metas);
            if (nfts.length < 5) {
                this.alert(t('App11Error1'));
                this.setState({
                    loading: false
                });
                return;
            }

            console.log(nfts);

            this.setState({
                currentTokens: nfts,
                loading: false
            });




        } catch (e) {
            console.error(e);
            this.setState({
                loading: false
            });
            this.alert(t('SubmitFail') + '  ' + (e.reason || e.message || e));
        } finally {
            this.setState({
                loading: false
            });
        }
    }

    isEnabled = async () => {
        const res = await fetchText(API + '/isEnabled');
        return res == 'true';
    }

    doSubmit = async () => {
        const t = this.props.t;
        // currentTokens must be 5
        if (this.state.currentTokens.length != 5) {
            this.alert('You do not have enough NFTs to synthesize');
            return;
        }
        // currentTokens tokenId must be different
        const tokenIds = this.state.currentTokens.map((nft) => {
            return nft.tokenId;
        });
        if (new Set(tokenIds).size != 5) {
            this.alert('You do not have enough NFTs to synthesize');
            return;
        }
        //  attrs.Attributes must be different
        const attributes = this.state.currentTokens.map((nft) => {
            return nft.attrs.Attributes;
        });
        if (new Set(attributes).size != 5) {
            this.alert('You do not have enough NFTs to synthesize');
            return;
        }

        // check loading
        if (this.state.loading) {
            return;
        }

        this.setState({
            loading: true
        });

        try {
            this.setState({
                loading: true
            });

            const options = await connect();
            const provider = options.provider;
            const signer = await provider.getSigner();
            const appContract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI_APP1, signer);

            const tokenIds = this.state.currentTokens.map((nft) => {
                return nft.tokenId.toString();
            });
            // https://meta-api.metadragon.ai/getSignature
            // const _api = 'https://meta-app1-worker.bdd-account.workers.dev/getSignature';
            const _api = API + '/getSignature';

            const res = await postJson(_api, {
                'address': ethers.getAddress(options.address),
                tokenIds
            });
            // 'function transfer(bytes memory signature,address to,uint256 amount,uint256 deadline,uint256[] memory tokenIds) external',
            console.log(res);
            const tx = await appContract.transfer(res.signature, res.to, res.amount, res.deadline, res.tokenIds);
            console.log(tx);

            this.setState({
                loading: false
            });
            const that = this;
            this.setState({
                txHash: tx.hash,
                showSuccessInfo: true,
                rewardPerOption: that.formatNumber(ethers.formatUnits(res.amount, 18) - 0)
            });
        } catch (e) {
            console.error(e);
            this.setState({
                loading: false
            });
            this.alert(t('SubmitFail') + '  ' + (e.reason || e.message || e));
        }
    }

    /*
        metas是所有符合条件的可以参与选择的nft，每个nft都有attrs.Attributes属性， 要从所有的nft中选取5个不同的Attributes的NFT
        并且优先meta.attrs.gaender = 'famale'的NFT，如果没有，则忽略famale的要求，只凑够5个不同的Attributes的NFT即可
    */
    _selectNfts2 = (metas) => {
        let nfts = [];
        //
        const famaleNfts = metas.filter((meta) => { return meta && meta.attrs && meta.attrs.Gender == 'famale' });

        if (famaleNfts > 5) {
            nfts = this._findNfts(famaleNfts)
            if (nfts.length == 5) {
                return nfts;
            }
        }
        // 这里没有return的话，说明famaleNfts不够5个或者满足条件的不够，那么忽略famale的要求，从所有的NFT中选取5个不同的Attributes的NFT
        nfts = this._findNfts(metas);
        return nfts;
    }

    _findNfts = (nfts) => {
        // 从给定的nfts里，找到5个不同的Attributes的NFT
        const existsAttributes = [];
        const result = [];
        for (let i = 0; i < nfts.length; i++) {
            const nft = nfts[i];
            console.log(nft.tokenId + ' = ' + nft.attrs.Attributes)
            if (existsAttributes.indexOf(nft.attrs.Attributes) == -1) {
                result.push(nft);
                existsAttributes.push(nft.attrs.Attributes);
            }
            if (result.length == 5) {
                return result;
            }
        }
        return result;
    }

    _selectNfts = (metas) => {
        const nfts = [];
        // 优先从所有 meta.attrs.Gender = 'famale'的NFT中选取5个 meta.attrs.Attributes 不同的 NFT
        // 如果无法满足选出来的5个nft的Genter都是famale，那么忽略famale的要求，选取5个不同的Attributes的NFT

        // filter all famale nfts
        const famaleNfts = metas.filter((meta) => { return meta && meta.attrs && meta.attrs.Gender == 'famale' });

        // 如果小于5个，那么忽略famale的要求
        if (famaleNfts.length >= 5) {
            // 尝试从famale中寻找5个不同的Attributes，找到后return，否则继续
            const existsAttributes = []
            for (let i = 0; i < famaleNfts.length; i++) {
                const nft = famaleNfts[i];
                if (existsAttributes.indexOf(nft.attrs.Attributes) == -1) {
                    nfts.push(nft);
                    existsAttributes.push(nft.attrs.Attributes);
                }
                if (nfts.length == 5) {
                    return nfts;
                }
            }
        }

        console.log('unfortunately, not enough famale NFTs')
        // 忽略famale的要求，从所有的NFT中选取5个不同的Attributes的NFT
        // 从metas中选取5个不同的NFT
        const existsAttributes = [];
        for (let i = 0; i < metas.length; i++) {
            const nft = metas[i];
            console.log(nft.tokenId + ' = ' + nft.attrs.Attributes)
            
            if (existsAttributes.indexOf(nft.attrs.Attributes) == -1) {
                nfts.push(nft);
                existsAttributes.push(nft.attrs.Attributes);
            }
            if (nfts.length == 5) {
                return nfts;
            }
        }

        return nfts;
    }

    onLoad = async () => {
        const isInWeb3 = isInWeb3Browser();
        this.setState({ isInWeb3 });
        if (isInWeb3) {
            const options = await connect();
            this.setState({
                provider: options.provider,
                address: options.address,
                chainId: options.chainId,
            });

            onAccountChange({
                success: (accounts) => {
                    this.setState({
                        address: accounts[0]
                    });
                }
            });

            if (options.chainId != CHAIN_ID) {
                if (window.confirm("You are on the wrong network, do you want to switch to " + CHAIN_NAME + "?")) {
                    switchNetwork({
                        chainId: CHAIN_ID,
                        chainname: CHAIN_NAME,
                        symbol: CHAIN_SYMBOL,
                        decimals: 18,
                        rpcUrls: [RPC],
                        explorer: CHAIN_EXPLORER
                    }, () => {
                        this.onLoad();
                    });
                }
            } else {
                // 
                this.initData(options)
            }
        }
    }

    shortAddress = (address) => {
        if (address) {
            return address.substr(0, 8) + '...' + address.substr(-6);
        }
    }


    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }


    //是否所有NFT都已经添加
    isAllNftAdded = () => {
        for (let i = 0; i < 6; i++) {
            const nft = this.slots[i].current.state;
            if (!nft.meta) {
                return false;
            }
        }
        return true;
    }

    formatNumber = (num) => {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    // 获取所有的NFT，如果是空的，那对应位置是null
    getAllNfts = async () => {
        // 验证
        const nfts = [];
        for (let i = 0; i < 6; i++) {
            const { nft } = this.slots[i].current.state;
            nfts[i] = nft;
        }
        console.log(nfts);
    }

    alert = (msg) => {
        this.setState({
            showError: true,
            message: msg
        });
    }

    ext = (source) => {
        // 去掉结尾.jpg，换成.png
        return source.substr(0, source.length - 4) + '.png';
    }

    initData = async (options) => {

        // 
        const enabled = await this.isEnabled();
        console.log('enabled: ' + enabled);
        this.setState({
            loadingEnabled: false,
            canCollect: enabled
        });

        const appContract = new ethers.Contract(EGGX_APP1_CONTRACT, ABI_APP1, options.provider);
        const that = this
        appContract.totalRelease().then((res) => {
            this.setState({
                totalReleased: that.formatNumber(ethers.formatUnits(res, 18) - 0)
            });
        })
        appContract.lockDays().then((res) => {
            this.setState({
                days: res
            });
        })
        // totalSupply
        appContract.totalSupply().then((res) => {
            this.setState({
                totalSupply: that.formatNumber(ethers.formatUnits(res, 18) - 0)
            });
        })
        // reward
        appContract.getReward().then((res) => {
            this.setState({
                rewardPerOption: that.formatNumber(ethers.formatUnits(res, 18) - 0),
                rewardPerOption1: that.formatNumber(ethers.formatUnits(res, 18) - 0)
            });
        })

        this._loadParent(options);
    }

    render() {
        const { t, i18n } = this.props;
        const { totalSupply, totalReleased, rewardPerOption, rewardPerOption1 } = this.state;

        const eggBoxStyle = {
            width: '5rem',
            height: '5rem',
            background: '#2F3837',
            borderRadius: '16px',
            border: '1px solid rgba(255,255,255,0.2)',
            backdropFilter: 'blur(8.119087334568768px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }

        const eggBoxRowStyle = {
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '2rem',
        }

        const chainId = this.state.chainId;

        return (<div style={{
            height: "100%",
            minHeight: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        }}>
            <div style={{
                maxWidth: "600px",
            }}>
                <Header address={this.state.address} title="App1" t={this.props.t} i18n={this.props.i18n} onClick={
                    () => {
                        this._onLoad();
                    }
                } chainId={chainId} onShowRule={(e) => {
                    this.setState({
                        showRule: true
                    })
                }} showInvite={true} />
                <div style={{
                    margin: '1rem'
                }}>
                    <Card>
                        <Card.Body>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                textAlign: "center",
                            }}>
                                <div style={{
                                    width: "33%",
                                }}>
                                    <div style={{
                                        fontSize: "1.2rem",
                                        fontWeight: "bold",
                                        color: "white",
                                    }}>{totalSupply}</div>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        marginTop: "0.5rem",
                                    }}>{t('TotalReward')}</div>
                                </div>
                                <div style={{
                                    width: "33%",
                                }} >
                                    <div style={{
                                        fontSize: "1.2rem",
                                        fontWeight: "bold",
                                        color: "white",
                                    }}>
                                        {/* {totalReleased} */}
                                        {this.state.showMaintenance ? '维护中/In maintenance' : totalReleased}
                                    </div>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        marginTop: "0.5rem",
                                    }}>
                                        {t('RewardIssued')}
                                    </div>
                                </div>
                                <div style={{
                                    width: "33%",
                                }}>
                                    <div style={{
                                        fontSize: "1.2rem",
                                        fontWeight: "bold",
                                        color: "white",
                                    }}>{rewardPerOption1} <span style={{ fontSize: '0.8rem' }}>±10%</span></div>
                                    <div style={{
                                        fontSize: "0.8rem",
                                        marginTop: "0.5rem",
                                    }}>
                                        {t('EveryReward')}
                                    </div>
                                </div>

                            </div>
                        </Card.Body>
                    </Card>

                    <div style={{
                        marginTop: '1rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                        <div>{t('App11Infomation')}</div>
                        <div>

                            <img src={require('../../assets/icons/info.png')} style={{
                                width: '1.2rem',
                                height: '1.2rem',
                            }} onClick={(e) => {
                                this.setState({
                                    showRule: true
                                });

                            }}></img>
                        </div>
                    </div>
                    {/* end of info */}

                    {/* start eggs */}
                    {this.state.currentTokens.length < 5 ? <div>
                        <div style={eggBoxRowStyle}>
                            <div style={eggBoxStyle}>
                                <img src={require('../../assets/icons/eggbg.png')} style={{
                                    width: '3rem',
                                    height: '3rem',
                                }}></img>
                            </div>
                            <div style={eggBoxStyle}>
                                <img src={require('../../assets/icons/eggbg.png')} style={{
                                    width: '3rem',
                                    height: '3rem',
                                }}></img>
                            </div>
                            <div style={eggBoxStyle}>
                                <img src={require('../../assets/icons/eggbg.png')} style={{
                                    width: '3rem',
                                    height: '3rem',
                                }}></img>
                            </div>
                        </div>
                        <div style={eggBoxRowStyle}>
                            <div style={eggBoxStyle}>
                                <img src={require('../../assets/icons/eggbg.png')} style={{
                                    width: '3rem',
                                    height: '3rem',
                                }}></img>
                            </div>
                            <div style={eggBoxStyle}>
                                <img src={require('../../assets/icons/eggbg.png')} style={{
                                    width: '3rem',
                                    height: '3rem',
                                }}></img>
                            </div>
                            {/* <div style={eggBoxStyle}>
                                <img src={require('../../assets/icons/eggbg.png')} style={{
                                    width: '3rem',
                                    height: '3rem',
                                }}></img>
                            </div> */}
                        </div>
                    </div> : <div>
                        {/* tokens === 5 */}
                        <div style={eggBoxRowStyle}>
                            <div>
                                <div style={eggBoxStyle}>
                                    <img src={this.ext(this.state.currentTokens[0].image)} style={{
                                        width: '4rem',
                                        height: '4rem',
                                    }}></img>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                }}>
                                    # {this.state.currentTokens[0].tokenId + ''}
                                </div>
                            </div>
                            <div>
                                <div style={eggBoxStyle}>
                                    <img src={this.ext(this.state.currentTokens[1].image)} style={{
                                        width: '4rem',
                                        height: '4rem',
                                    }}></img>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                }}>
                                    # {this.state.currentTokens[1].tokenId + ''}
                                </div>
                            </div>
                            <div>
                                <div style={eggBoxStyle}>
                                    <img src={this.ext(this.state.currentTokens[2].image)} style={{
                                        width: '4rem',
                                        height: '4rem',
                                    }}></img>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                }}>
                                    # {this.state.currentTokens[2].tokenId + ''}
                                </div>
                            </div>
                        </div>
                        <div style={eggBoxRowStyle}>
                            <div>
                                <div style={eggBoxStyle}>
                                    <img src={this.ext(this.state.currentTokens[3].image)} style={{
                                        width: '4rem',
                                        height: '4rem',
                                    }}></img>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                }}>
                                    # {this.state.currentTokens[3].tokenId + ''}
                                </div>
                            </div>
                            <div>
                                <div style={eggBoxStyle}>
                                    <img src={this.ext(this.state.currentTokens[4].image)} style={{
                                        width: '4rem',
                                        height: '4rem',
                                    }}></img>
                                </div>
                                <div style={{
                                    textAlign: 'center',
                                }}>
                                    # {this.state.currentTokens[4].tokenId + ''}
                                </div>
                            </div>
                            {/* <div style={eggBoxStyle}>

                            </div> */}
                        </div>

                    </div>}

                    {/* end of eggs */}
                    <div style={{
                        position: 'fixed',
                        bottom: '1rem',
                        left: '0',
                        right: '0',
                        padding: '1rem',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        
                        <div className="d-grid gap-2" style={{ marginTop: '1.2rem', maxWidth: '600px', width: '100%' }}>
                        <div>
                            {this.state.canCollect ? <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '0.8rem',
                            }}>
                                <img src={require('../../assets/icons/rise.png')} style={{
                                    width: '1.2rem',
                                    height: '1.2rem',
                                }}></img>
                                <div>$META 行情上涨,GMT00:00~24:00之间可以领取空投</div>
                            </div>:
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: '0.8rem',
                            }}>
                                <img src={require('../../assets/icons/fall.png')} style={{
                                    width: '1.2rem',
                                    height: '1.2rem',
                                }}></img>

                                <div>$META 行情下跌,GMT00:00~24:00之间暂停领取空投</div>
                            </div>}
                        </div>
                            {/* 背景是从#86EFEF到#A9FB7D过渡色 */}
                        {/* 背景是从#86EFEF到#A9FB7D过渡色 */}
                            <Button style={{
                                // backgroundImage: "linear-gradient(45deg, #86EFEF, #A9FB7D)",
                                // color: "black",
                                // border: "none",
                                // height: "3.6rem",
                            }} variant="warning" size="lg" 
                                disabled={!(!this.state.loadingEnabled && this.state.canCollect)}
                            onClick={(e) => {
                                if (this.state.currentTokens.length < 5) {
                                    this.scanAndLoadTokens();
                                    return;
                                } else {
                                    this.doSubmit();
                                }
                            }}>
                                {this.state.currentTokens.length == 5 ? t('App11Synthesize') : t('App11PlusMetaNFT')}
                            </Button>
                        </div>
                    </div>
                </div>

            </div>

            {/* loading mask */}
            {this.state.loading && <div style={{
                position: 'fixed',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                background: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '2rem',
            }}>
                {/* center text white */}
                <div>{this.state.loadingMessage}</div>
            </div>}

            {/* show error */}
            {/* show error */}
            {this.state.showError ? <div style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "999",
            }} onClick={(e) => {
                this.setState({
                    showError: false
                });

            }}>
                <div style={{
                    width: "80%",
                    backgroundColor: "white",
                    padding: "1rem",
                    borderRadius: "1.5rem",
                    position: "relative",
                }}>
                    <div style={{
                        textAlign: "center"
                        , marginBottom: "2rem"
                        , marginTop: "2rem"
                    }}>
                        <img src={require('../../assets/icons/error2.png')} style={{
                            width: "3rem",
                            height: "3rem",
                        }} onClick={(e) => {
                            this.setState({
                                showError: false
                            });
                        }} />
                    </div>
                    <div style={{
                        textAlign: "center",
                        color: "black",
                        marginBottom: "2rem"
                    }}>
                        {this.state.message}
                    </div>
                    <div>
                        <center style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: "black",
                        }} onClick={(e) => {
                            this.setState({
                                showError: false
                            });

                        }}>
                            OK
                        </center>
                    </div>
                </div>
            </div> : null}

            {/* show rule */}
            {this.state.showRule ? <div style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "999",
            }} onClick={(e) => {
                this.setState({
                    showRule: false
                });

            }}>
                <div style={{
                    width: "80%",
                    backgroundColor: "white",
                    padding: "1rem",
                    borderRadius: "1.5rem",
                    position: "relative",
                }}>
                    {/* <div style={{
                        textAlign: "center"
                        , marginBottom: "2rem"
                        , marginTop: "2rem"
                    }}>
                        <img src={require('../../assets/icons/rule.png')} style={{
                            width: "3rem",
                            height: "3rem",
                        }} onClick={(e) => {
                            this.setState({
                                showRule: false
                            });
                        }} />
                    </div> */}
                    <div style={{
                        textAlign: "left",
                        color: "black",
                        marginBottom: "2rem",
                        lineHeight: 2
                    }}>
                        {/* t('App1Rule') 用\n分成数组并遍历，用<div>输出每一行 */}
                        {t('App1Rule').split('\n').map((item, i) => {
                            return <div key={i}>{item}</div>
                        })}
                    </div>
                    <div>
                        <center style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: "black",
                        }} onClick={(e) => {
                            this.setState({
                                showRule: false
                            });

                        }}>
                            OK
                        </center>
                    </div>
                </div>
            </div> : null}

            {/* show success info */}

            {this.state.showSuccessInfo ? <div style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: "999",
            }} onClick={(e) => {
                this.setState({
                    showSuccessInfo: false
                });

            }}>
                <div style={{
                    width: "80%",
                    backgroundColor: "white",
                    padding: "1rem",
                    borderRadius: "1.5rem",
                    position: "relative",
                }}>
                    <div style={{
                        textAlign: "center"
                        , marginBottom: "2rem"
                        , marginTop: "2rem"
                    }}>
                        <img src={require('../../assets/icons/waiting.png')} style={{
                            width: "3rem",
                            height: "3rem",
                        }} onClick={(e) => {
                            this.setState({
                                showSuccessInfo: false
                            });
                        }} />
                    </div>
                    <div style={{
                        textAlign: "center",
                        color: "black",
                        marginBottom: "2rem"
                    }}>
                        <div>{t('ResultMessage1')} {rewardPerOption} META</div>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                        }}>
                            Hash: {this.shortAddress(this.state.txHash)}
                            <img src={require('../../assets/icons/copy.png')} style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "0.5rem",
                            }} onClick={(e) => {
                                copy(this.state.txHash);
                            }} />
                        </div>
                        <span style={{
                            color: "blue",
                            textDecoration: "underline",
                        }} onClick={(e) => {
                            document.location.href = CHAIN_EXPLORER + '/tx/' + this.state.txHash;
                        }}>{t('ResultMessage2')}</span>
                    </div>
                    <div>
                        <center style={{
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                            color: "black",
                        }} onClick={(e) => {
                            this.setState({
                                showSuccessInfo: false
                            });
                            // reload
                            document.location.reload();
                        }}>
                            OK
                        </center>
                    </div>
                </div>
            </div> : null}

        </div>);
    }

}

function App12Page() {
    const { t, i18n } = useTranslation();
    return (
        <_App12Page t={t} i18n={i18n} />
    )
}

export default App12Page;